import axios from "axios";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";

import useCookie from "../../hooks/cookieHook";
import { useAuthContext } from "../../contexts/authContext";
import useURLQueryParameter from "../../hooks/queryParamHook";
import Header from "../../components/Header";
import LoadingStatus from "../../components/LoadingStatus";

const fetch = async (
  code: string,
  codeVerifier: string,
  oktaDomain: string = "bamfunds-ext.okta.com",
  oktaProtocol: string = "https",
  oktaRedirectURI: string = `${process.env.GATSBY_APPLICATION_URL}/login/okta`,
  oktaClientId: string = process.env.GATSBY_OKTA_CLIENT_ID,
) => {
  const parameters = new URLSearchParams();

  parameters.append("grant_type", "authorization_code");
  parameters.append("client_id", oktaClientId);
  parameters.append("redirect_uri", oktaRedirectURI);
  parameters.append("code", code);
  oktaRedirectURI;
  parameters.append("code_verifier", codeVerifier);

  const { data } = await axios.post(
    new URL("/oauth2/v1/token", `${oktaProtocol}://${oktaDomain}`).href,
    parameters,
  );

  const { access_token, id_token, refresh_token } = data;

  return {
    idToken: id_token,
    accessToken: access_token,
    refreshToken: refresh_token,
  };
};

const LoginPage = () => {
  const { accessToken, setAccessToken, refreshToken, setRefreshToken } =
    useAuthContext();

  const [codeQueryParameter] = useURLQueryParameter("code");
  const [stateQueryParameter] = useURLQueryParameter("state");

  const [verifierCookie, , removeVerifierCookie] = useCookie("verifier");
  const [stateCookie, , removeStateCookie] = useCookie("state");

  useEffect(() => {
    if (refreshToken && accessToken) navigate("/");
  }, [refreshToken, accessToken]);

  useEffect(() => {
    if (refreshToken && accessToken) {
      return;
    }

    if (
      !(
        codeQueryParameter &&
        stateQueryParameter &&
        verifierCookie &&
        stateCookie
      )
    )
      return;

    if (stateCookie !== stateQueryParameter) return;

    let ignore = false;

    fetch(codeQueryParameter, verifierCookie, "bamfunds-ext.okta.com")
      .catch((_) => {
        if (!ignore) {
          removeVerifierCookie();
          removeStateCookie();
          navigate("/login");
        }
      })
      .then((value) => {
        if (!ignore) {
          /**
           * The order here's important given the hook's
           * `useEffect` implementation! If the refresh token
           * is set first, the null access token will trigger
           * an exchange.
           */
          setAccessToken(value.accessToken);
          setRefreshToken(value.refreshToken);
        }
      });

    return () => {
      ignore = true;
    };
  }, [codeQueryParameter, stateQueryParameter, verifierCookie, stateCookie]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Authenticating...</title>
      </Helmet>
      <div className="flex flex-col flex-1 w-full overflow-hidden bg-bam-light-blue/50">
        <Header showNav={false} />
        <main className="w-screen h-screen bg-off-white flex flex-center">
          <LoadingStatus loadingType="login_loading" />
        </main>
      </div>
    </>
  );
};

export const Head = () => <title>Authentication</title>;

export default LoginPage;
